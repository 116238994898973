import React, {useEffect, useRef, useState} from "react";
import {E164Number} from "libphonenumber-js";
import {useGeolocated} from "react-geolocated";
import {toast} from "react-toastify";
import logo from "../assets/picto.png";
import qrcode from "../assets/qrcode.png";
import menu from "../assets/menu.png";
import pin from "../assets/pin.png";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ButtonGroup, Dropdown, DropdownButton} from "react-bootstrap";

type Location = {
    latitude: number;
    longitude: number;
};
const Main = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [background] = useState<string>(localStorage.getItem('background') || 'null')
    const [user] = useState<any | undefined>(
        JSON.parse(localStorage.getItem('user') || '[]')
    );
    const [previousLocation, setPreviousLocation] = useState<Location | null>(null);
    const {coords, isGeolocationAvailable, isGeolocationEnabled} =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
            },
            userDecisionTimeout: 15000,
            watchPosition: true,
        });
    const [launching, setLaunching] = useState(false);
    const [launched, setLaunched] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [params] = useSearchParams();
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [disconnected, setDisconnected] = useState<boolean>(false);
    const isInStandaloneMode = () => window.matchMedia('(display-mode: standalone)').matches;
    const togglePasswordVisibility = () => {
        setPasswordVisible(!isPasswordVisible);
    };

    const handleSendSMS = (e: React.MouseEvent<HTMLImageElement>) => {
        sendAlarm()
    }

    const sendAlarm = () => {
        const correspondents = localStorage.getItem('correspondents');
        if (correspondents === null) {
            navigate('/correspondents');
            return;
        }

        if (coords?.latitude !== null) {
            setLaunching(true);
            fetch(`${process.env.REACT_APP_API_URL}/alarms/user/${localStorage.getItem('user_key')}`)
                .then(response => response.json())
                .then(data => {
                        if (data.success == true) {
                            const requestOptions = {
                                method: 'POST',
                                headers: {'Content-Type': 'application/json'},
                                body: JSON.stringify({
                                    message: `SOS de ${user.name} ${user.number} localisé https://www.google.com/maps?q=${coords?.latitude},${coords?.longitude}, Lien de tracking : https://sentinel.urgent-assistance.com/tracking/${localStorage.getItem('user_key')}`,
                                    correspondents: JSON.parse(correspondents),
                                    user_id: localStorage.getItem('user_key'),
                                    latitude: coords?.latitude,
                                    longitude: coords?.longitude,
                                })
                            };
                            fetch(`${process.env.REACT_APP_API_URL}/alarms`, requestOptions)
                                .then(response => response.json())
                                .then(data => {
                                    setLaunched(true);
                                    setLaunching(false);
                                    setTimeout(() => {
                                        setLaunched(false)
                                    }, 3000)
                                    if (data.success !== true) {
                                        toast.error(data.error, {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "light",
                                        });
                                        if (data?.reset !== undefined) {
                                            localStorage.clear();
                                            navigate('/install');
                                            return;
                                        }
                                    }

                                })
                                .catch(() => {

                                });
                        } else {
                            setLaunching(false);
                            toast.error(data.error, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    }
                )
                .catch(() => {
                    setLaunching(false);
                    toast.error("Clé utilisateur invalide", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    navigate('/install');
                });
        } else {
            toast.error('Localisation impossible', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    const sendLocationUpdate = async (location: Location) => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                user_id: localStorage.getItem('user_key'),
                latitude: location.latitude,
                longitude: location.longitude
            })
        };

        fetch(`${process.env.REACT_APP_API_URL}/users/update-location`, requestOptions)
            .catch((e) => {

            })
    };
    const handlePassword = () => {
        if (user.password !== password) {
            toast.error('Mot de passe érroné', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            setShowModal(false)
            setPassword('')
            localStorage.setItem('logged', 'true')
            setDisconnected(true)
        }
    }

    const logout = () => {
        localStorage.removeItem('logged')
        setShowModal(true)
    }

    useEffect(() => {
        const k = params.get('key')
        if (k !== null) {
            localStorage.setItem('user_key', k)
        }

        if (!isInStandaloneMode()) {
            navigate('/install-first');
            return;
        }
        const first = localStorage.getItem('first_launch');
        if (first === null) {
            navigate('/install');
            return;
        }

        const correspondents = localStorage.getItem('correspondents');
        if (correspondents === null) {
            navigate('/correspondents');
            return;
        }

        if (Object.keys(user).length === 0) {
            navigate('/install');
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({id: localStorage.getItem('user_key')})
        };

        fetch(`${process.env.REACT_APP_API_URL}/users/check-id`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.success !== true) {
                    localStorage.clear()
                    navigate('/install')
                } else {
                    const status = localStorage.getItem('logged')
                    if (status === null) {
                        setShowModal(true)
                    }
                }
            })
            .catch((e) => {
                localStorage.clear()
                navigate('/install')
            })


    }, []);

    useEffect(() => {
        if (coords) {
            const currentLocation = {latitude: coords.latitude, longitude: coords.longitude};
            if (
                !previousLocation ||
                previousLocation.latitude !== currentLocation.latitude ||
                previousLocation.longitude !== currentLocation.longitude
            ) {
                // Update location in the database
                sendLocationUpdate(currentLocation);
                setPreviousLocation(currentLocation);
            }
        }
    }, [coords, previousLocation]);

    return !isGeolocationAvailable ? (
        <div className="container text-center mt-5 pt-5 vh-100">
            <div className="bg-danger p-5 rounded">
                {t('messages.geolocation-error')}
            </div>
        </div>
    ) : !isGeolocationEnabled ? (
        <div className="container text-center mt-5 pt-5 vh-100">
            <div className="bg-danger p-5 rounded">
                {t('messages.activate-geolocation')}
            </div>
        </div>
    ) : (
        <div className="container col-12 col-md-4 ms-auto me-auto pt-5" style={background != 'null' ? {
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover'
        } : {
            background: 'black'
        }}>
            <div className="d-flex justify-content-center mt-5 pt-5">
                <img
                    onDoubleClick={handleSendSMS}
                    src={logo}
                    className="img-fluid mt-5"
                    style={background != 'null' ? {
                        width: '80%',
                        opacity: '0.5'
                    } : {width: '75%'}} alt=''/>
            </div>
            <div className='position-absolute bottom-0 mb-3' style={{
                marginLeft: '4rem'
            }}>
                <img
                    src={qrcode}
                    className="img-fluid mt-5"
                    style={{
                        width: '10%',
                    }} alt=''/>
            </div>
            <div className='position-absolute bottom-0 mb-3' style={{
                left: "40%"
            }}>
                <Link to='/my-location'>
                    <img
                        src={pin}
                        className="img-fluid mt-5"
                        style={{
                            width: '30%',
                        }} alt=''/>
                </Link>
            </div>
            <div className='text-center mt-5'>
                {launched && (
                    <div className='text-success'>
                        <i className="fa-solid fa-circle-check" style={{
                            fontSize: '150px'
                        }}></i>
                        <h3 className='mt-2'></h3>
                    </div>
                )}
            </div>
            <div className="position-absolute bottom-0 end-0 mb-3" style={{
                marginRight: '4rem'
            }}>
                <div className="d-flex justify-content-center mt-5">
                    <Dropdown as={ButtonGroup} drop="up-centered">
                        <Dropdown.Toggle as="div" variant="secondary" id="dropdown-custom-components">
                            <img
                                src={menu}
                                className="img-fluid mt-5"
                                alt="menu"
                                style={{cursor: 'pointer'}} // Optional: adds pointer cursor on hover
                            />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="1">
                                <Link to='/my-account' className='text-decoration-none text-black'>Mon compte</Link>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="2">
                                <Link to='/correspondents' className='text-decoration-none text-black'>Contacts</Link>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="3">
                                <Link to='/background' className='text-decoration-none text-black'>Fond
                                    d'écran</Link>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="4">
                                <Link to='/notice' className='text-decoration-none text-black'>Notice
                                    d'utilisation</Link>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="5">
                                <Link to='/legal-mentions' className='text-decoration-none text-black'>Mentions
                                    légales</Link>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="6">
                                <Link to='/about' className='text-decoration-none text-black'>À propos</Link>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="7">
                                <span className='text-danger' onClick={logout}>Se déconnecter</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            {/* Modal */}
            {showModal && (
                <div className="modal show" tabIndex={-1} style={{display: 'block'}}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            {!disconnected && (
                                <div>
                                    <div className="modal-header">
                                        <h5 className="modal-title">Mot de passe</h5>
                                    </div>
                                    <div className="modal-body">
                                        <div className="input-group mb-3">
                                            <input
                                                placeholder="Mot de passe"
                                                type={isPasswordVisible ? 'text' : 'password'}
                                                className="form-control"
                                                value={password}
                                                onChange={(event) => setPassword(event.target.value)}
                                            />
                                            <span className="input-group-text" onClick={togglePasswordVisibility}
                                                  style={{cursor: 'pointer'}}>
                            {isPasswordVisible ? <i className='fa fa-eye'></i> :
                                <i className='fa fa-eye-slash'></i>}
                          </span>
                                        </div>
                                        <div className='text-center mt-3'>
                                            <button className='btn btn-outline-dark'
                                                    onClick={handlePassword}>
                                                {t('buttons.validate')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {disconnected && (
                                <div>
                                    <div className="modal-header">
                                        <h5 className="modal-title">Deconnecté</h5>
                                    </div>
                                    <div className="modal-body">
                                        Vous êtes déconnecté
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {/* Modal backdrop */}
            {showModal && <div className="modal-backdrop fade show"></div>}
        </div>
    )
}

export default Main;