import {Link} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";
import {Accordion} from "react-bootstrap";

const Notice = () => {
    const {t} = useTranslation();
    return (
        <div className='container col-12 col-md-4 ms-auto me-auto h-100'>
            <h3 className='mt-3 mb-3 text-center'>Notice d'utilisation</h3>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Présentation</Accordion.Header>
                    <Accordion.Body>
                        <strong>Dans l’urgence, chaque seconde compte.</strong><br/>
                        Urgent Assistance permet d’envoyer un SMS avec géolocalisation GPS vers 1 à 3 contacts par :
                        <ul>
                            <li>double-clic discret sur la touche SOS
                                L’envoi du SMS est confirmé par un bip sonore.
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Contacts d’urgence</Accordion.Header>
                    <Accordion.Body>
                        Les contacts d’urgence sont choisis
                        librement parmi les personnes de confiance dans ton entourage proche : famille, amis, voisins
                        qui peuvent venir de te porter
                        secours ou appeler les services d’urgence
                        ou la police.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Double clic</Accordion.Header>
                    <Accordion.Body>
                        Téléphone en main, il suffit de cliquer 2 fois sur le cercle d’urgence pour envoyer le SMS de
                        demande de secours : L’envoi du SMS est confirmé par un bip sonore
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>SMS et géolocalisation GPS</Accordion.Header>
                    <Accordion.Body>
                        Le SMS de demande de secours est libellé comme suit :<br/>
                        SOS de NOM PRENOM<br/>
                        +## # ## ## ## ##<br/>
                        localisé https://www.google.com/maps?<br/>
                        Clique sur :
                        <ul>
                            <li>
                                le n° de téléphone pour rappeler directement
                                ton correspondant
                            </li>
                            <li>
                                l’hyperlien pour afficher sa géolocalisation GPS
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Fonds d’écran</Accordion.Header>
                    <Accordion.Body>
                        Tu peux choisir ton fonds d’écran dans notre gamme comprenant plusieurs thèmes. Ces fonds
                        d’écran sont destinés à une utilisation discrète de l’application dans une situation à risque.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header><span className='text-danger'>
                        Important
                    </span></Accordion.Header>
                    <Accordion.Body>
                        <span className='text-danger'>
                            URGENT Assistance est une application de <strong>demande de secours</strong>.
                            Donc, <strong>anticipe</strong> toute situation potentielle à risque et active URGENT Assistance par précaution
                        <strong> avant</strong> le risque.
                        </span>
                        <br/>
                        Urgent Assistance fonctionne :
                        <ul>
                            <li className='list'>
                                en premier plan, affichée sur ton écran, d’accueil
                            </li>
                            <li className='list'>
                                par double-clic sur le logo rouge
                            </li>
                            <li className='list'>
                                partout où tu as du réseau et accès à internet
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <div className="text-center mt-5">
                <Link to='/' className="btn bg-black text-white">{t('buttons.close')}</Link>
            </div>
        </div>
    )
}

export default Notice;