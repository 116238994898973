import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function About() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [user] = useState(JSON.parse(localStorage.getItem('user') || '[]'))

    useEffect(() => {
        const first = localStorage.getItem('first_launch');
        if (first === null) {
            navigate('/install');
            return;
        }

    }, [])

    return (
        <div className='container col-12 col-md-4 ms-auto me-auto'>
            <nav className="navbar bg-body-tertiary">
                <div className='navbar-nav'>
                    <Link to='/' className="navbar-brand mb-0 h1 d-none">{t('buttons.close')}</Link>
                </div>
                <form className="d-flex" role="search">
                    <div className='navbar-nav'>
                        <Link to='/' className="navbar-brand mb-0 h1">{t('buttons.close')}</Link>
                    </div>
                </form>
            </nav>
            <div className='text-center mt-5'>
                <h4>{t('messages.about')}</h4>
            </div>
            <div className='p-3'>
                <p>
                    Urgent Assistance © {new Date().getFullYear()}<br/>
                    {t('messages.distributed')}
                </p>
                <p>{t('messages.reserved')}</p>
                <p>
                    {t('messages.licensed')}<br/>
                    {user.name}<br/>
                    {t('messages.at')} {user.number}
                </p>
                <p>
                    {t('messages.advert')} :
                </p>
                <p style={{
                    textAlign: 'justify'
                }}>
                    {t('messages.about1')}
                </p>
                <p style={{
                    textAlign: 'justify'
                }}>
                    {t('messages.about2')}
                </p>
            </div>

        </div>
    )
}